import React from 'react';
import { Carousel, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './css/Carousel.css';
import chairOne from "../../assets/img/Components/Carousel/Escenografy/Silla_Entrevistados.png";
import chairTwo from "../../assets/img/Components/Carousel/Escenografy/Silla_Jhonny.png";
import recorder from "../../assets/img/Components/Carousel/Escenografy/Grabadora.png";
import boot from "../../assets/img/Components/Carousel/Escenografy/Bota_Campeche.png";
import bushes from "../../assets/img/Components/Carousel/Escenografy/Maticas.png";
import woodenSign from "../../assets/img/Components/Carousel/Escenografy/Letrero_de_Madera.png";
import pot from "../../assets/img/Components/Carousel/Escenografy/Maseta_con_Madeja.png";



const CarouselImgs = ({ imgs }) => {
    let navigate = useNavigate();

    let imagesSelection = [
        {
            srcImage: chairOne,
            altText: 'Silla Invitados',
            classNameImage: 'chairOne',
            routePath: '/videos',
            urlLink: '',
            tooltipText: 'Videos'
        },
        {
            srcImage: chairTwo,
            altText: 'Silla Jhonny',
            classNameImage: 'chairTwo',
            routePath: '/galeria',
            urlLink: '',
            tooltipText: 'Galeria'
        },
        {
            srcImage: recorder,
            altText: 'Grabadora',
            classNameImage: 'recorder',
            routePath: '/emisiones',
            urlLink: '',
            tooltipText: 'Emisiones'
        },
        {
            srcImage: boot,
            altText: 'Bota',
            classNameImage: 'boot',
            routePath: '/',
            urlLink: 'https://m.facebook.com/Emisora-La-Terraza-103458519013361/?ref=page_internal',
            tooltipText: '¡Facebook!'
        },
        {
            srcImage: bushes,
            altText: 'Maticas',
            classNameImage: 'bushes',
            routePath: '/somos',
            urlLink: '',
            tooltipText: 'Somos'
        },
        {
            srcImage: woodenSign,
            altText: 'Letrero de Madera',
            classNameImage: 'woodenSign',
            routePath: '/',
            urlLink: 'https://www.youtube.com/channel/UCSzd0W509bSMQM2nc_r80eQ',
            tooltipText: '¡Youtube!'
        },
        {
            srcImage: pot,
            altText: 'Maseta',
            classNameImage: 'pot',
            routePath: '',
            urlLink: '',
            tooltipText: ''
        },
    ]

    const gotToPage = ( link ) => {
        window.open( link );
    }

    return (
        <>
            <div className='container-escescenography'>
                {   
                    imagesSelection.map( ( e, key ) => {
                        return( 
                            <>
                                    { e.routePath != '' ? (
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip>{ e.tooltipText }</Tooltip>}
                                        >
                                            { e.urlLink !== '' ? (
                                                    <img key={key} src={ e.srcImage } alt={ e.altText } className={ e.classNameImage } onClick={ () => {gotToPage( e.urlLink )}} />
                                            ) : (
                                                <img key={key} src={ e.srcImage } alt={ e.altText } className={ e.classNameImage } onClick={ () => {navigate(e.routePath)} } />
                                            ) }
                                            
                                        </OverlayTrigger>
                                        ) : (
                                            <img key={key} src={ e.srcImage } alt={ e.altText } className={ e.classNameImage } />
                                        )   
                                    }
                            </>
                        )
                    })
                }
            </div>
            <Container className='escenografiaBackground'>
                <Container className='markContainer'>
                    <Carousel>
                        {
                            imgs.map( ( e, i ) => {
                                return (
                                    <Carousel.Item>
                                    <img
                                        className='d-block w-100 imgRem' 
                                        src={e.urlImg} 
                                        alt={e.imgAlt} 
                                        key={i}
                                    />
                                </Carousel.Item>
                                )
                            } )
                        }
                    </Carousel>
                </Container>
            </Container>
        </>
    );
}

export default CarouselImgs;