import React , { useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import "./css/Galery.css";

// Images
import Image_1 from "../../assets/img/Views/Galery/Images_season1/4.jpg";
import Image_2 from "../../assets/img/Views/Galery/Images_season1/5.jpg";
import Image_3 from "../../assets/img/Views/Galery/Images_season1/6.jpg";
import Image_4 from "../../assets/img/Views/Galery/Images_season1/7.jpg";
import Image_5 from "../../assets/img/Views/Galery/Images_season1/8.jpg";
import Image_6 from "../../assets/img/Views/Galery/Images_season1/9.jpg";
import Image_7 from "../../assets/img/Views/Galery/Images_season1/10.jpg";
import Image_8 from "../../assets/img/Views/Galery/Images_season1/11.jpg";
import Image_9 from "../../assets/img/Views/Galery/Images_season1/12.jpg";
import Image_10 from "../../assets/img/Views/Galery/Images_season1/13.jpg";
import Image_11 from "../../assets/img/Views/Galery/Images_season1/14.jpg";
import Image_12 from "../../assets/img/Views/Galery/Images_season1/15.jpg";
import Image_13 from "../../assets/img/Views/Galery/Images_season1/16.jpg";
import Image_14 from "../../assets/img/Views/Galery/Images_season1/17.jpg";
import Image_15 from "../../assets/img/Views/Galery/Images_season1/18.jpg";
import Image_16 from "../../assets/img/Views/Galery/Images_season1/19.jpg";
import Image_17 from "../../assets/img/Views/Galery/Images_season1/20.jpg";
import Image_18 from "../../assets/img/Views/Galery/Images_season1/21.jpg";
import Image_19 from "../../assets/img/Views/Galery/Images_season1/22.jpg";
import Image_20 from "../../assets/img/Views/Galery/Images_season1/23.jpg";
import Image_21 from "../../assets/img/Views/Galery/Images_season1/24.jpg";
import Image_22 from "../../assets/img/Views/Galery/Images_season1/25.jpg";
import Image_23 from "../../assets/img/Views/Galery/Images_season1/26.jpg";
import Image_24 from "../../assets/img/Views/Galery/Images_season1/27.jpg";
import Image_25 from "../../assets/img/Views/Galery/Images_season1/28.jpg";
import Image_26 from "../../assets/img/Views/Galery/Images_season1/29.jpg";

// Images season 2
import Image_2_1 from "../../assets/img/Views/Galery/Images_season2/1.jpg";
import Image_2_2 from "../../assets/img/Views/Galery/Images_season2/2.jpg";
import Image_2_3 from "../../assets/img/Views/Galery/Images_season2/3.jpg";
import Image_2_4 from "../../assets/img/Views/Galery/Images_season2/4.jpg";
import Image_2_5 from "../../assets/img/Views/Galery/Images_season2/5.jpg";
import Image_2_6 from "../../assets/img/Views/Galery/Images_season2/6.jpg";
import Image_2_7 from "../../assets/img/Views/Galery/Images_season2/7.jpg";
import Image_2_8 from "../../assets/img/Views/Galery/Images_season2/8.jpg";
import Image_2_9 from "../../assets/img/Views/Galery/Images_season2/9.jpg";
import Image_2_10 from "../../assets/img/Views/Galery/Images_season2/10.jpg";
import Image_2_11 from "../../assets/img/Views/Galery/Images_season2/11.jpg";
import Image_2_12 from "../../assets/img/Views/Galery/Images_season2/12.jpg";
import Image_2_13 from "../../assets/img/Views/Galery/Images_season2/13.jpg";
import Image_2_14 from "../../assets/img/Views/Galery/Images_season2/14.jpg";
import Image_2_15 from "../../assets/img/Views/Galery/Images_season2/15.jpg";
import Image_2_16 from "../../assets/img/Views/Galery/Images_season2/16.jpg";
import Image_2_17 from "../../assets/img/Views/Galery/Images_season2/17.jpg";
import Image_2_18 from "../../assets/img/Views/Galery/Images_season2/18.jpg";
import Image_2_19 from "../../assets/img/Views/Galery/Images_season2/19.jpg";
import Image_2_20 from "../../assets/img/Views/Galery/Images_season2/20.jpg";
import Image_2_21 from "../../assets/img/Views/Galery/Images_season2/21.jpg";
import Image_2_22 from "../../assets/img/Views/Galery/Images_season2/22.jpg";
import Image_2_23 from "../../assets/img/Views/Galery/Images_season2/23.jpg";
import Image_2_24 from "../../assets/img/Views/Galery/Images_season2/24.jpg";
import Image_2_25 from "../../assets/img/Views/Galery/Images_season2/25.jpg";
import Image_2_26 from "../../assets/img/Views/Galery/Images_season2/26.jpg";
import Image_2_27 from "../../assets/img/Views/Galery/Images_season2/27.jpg";

import location_1 from "../../assets/img/Views/Galery/location1.jpg";
import location_2 from "../../assets/img/Views/Galery/location2.jpg";
import location_3 from "../../assets/img/Views/Galery/location3.jpg";
import woodSing from "../../assets/img/Components/Carousel/Escenografy/Letrero_de_Madera.png";


// import bush from 

const Galery = () => {

    const [ show, setShow ] = useState( false );
    const [ imageChange, setImageChange ] = useState( );
    const [ seasonClick, setSeasonClick ] = useState( 1 );
    const [sizeBool, setSizeBool ] = useState(true);

    const activeSeason = ( number ) => {
        setSeasonClick( number );
    }

    const sizeAllTime =  () => {
        return window.screen.width;
    }

    let globalSize;

    


    let size ;
    setInterval(() => {
        size = sizeAllTime();
        globalSize = size;
        if ( globalSize <= 600 ) {
            setSizeBool(true);
        } else {
            setSizeBool(false);
        }
    }, 100);

    const showModal = ( element ) => {
        let imageName = element.target.src;

        setShow( true );
        console.log( element );
        
        setImageChange( imageName );
    }

    const handleClose = () => {
        setShow( false );
    }   


    return (
        <>
            <section className="container-galery mt-2">
                <div className="d-flex justify-content-center">
                    <p className="galery-title">GALERIA</p>
                </div>
               <Container>
                    <p className="title-galery">Radio la terraza</p>
                    <p className="text-galery_1">
                        Es un ejrcicio artistico a modo de intervencion, haciendo de la casa de paroy y zinc,
                        un módulo arquitectonico con estos elementos que estructuran la memoria del entomos que 
                        habitamos y por ello los convertimos en nuestro sed, espacio donde entrevistamos 
                        diferentes procesos esteticos reales, propios, que buscan introducirse en 
                        memorias, la emisora "LA TERRAZA" es un magazin que busca promover la 
                        producción de contenido academico, artistico, ambiental y social a travez de las 
                        voces de los artistas, colectivos, lideres, actores culturales, entre otros, cuyo resultado
                        sera sistematizado en la producción de programas transmedia ( audiovisual y podcast ) 
                        producidos en un en vivo a traves de plataforma web, desde terrazas como espacios
                        no convencionales en contextos perifericos de la localidad que son posteriormente
                        editados  para construir un archivo que recompila lo mejor de los contextos
                        periurbanos.    
                    </p>
                    {
                        sizeBool ? (
                            <>
                                <Row className="container-primay-images">
                                    <Col className="col-imgs-galery"> <img src={location_1} className="w-100" alt="" srcset="" /> </Col>
                                    <Col className="col-imgs-galery"> <img src={location_2} className="w-100" alt="" srcset="" /> </Col>
                                    <Col className="col-imgs-galery"> <img src={location_3} className="w-100" alt="" srcset="" /> </Col>
                                </Row>
                            </>
                        ) : (
                            <>        
                                <Row className="container-primay-images">
                                    <Col className="col-imgs-galery"> <img src={location_1} alt="" srcset="" /> </Col>
                                    <Col className="col-imgs-galery"> <img src={location_2} alt="" srcset="" /> </Col>
                                    <Col className="col-imgs-galery"> <img src={location_3} alt="" srcset="" /> </Col>
                                </Row>
                            </>
                        )
                    }
                    <div>
                        <div className="container-sings">
                            <div>
                                <p className="season-text-1" onClick={ ( ) => { activeSeason( 1 )  } }>Temporada 1</p>
                                <img src={woodSing} alt="Image Season 1" className="sing-season-1" />
                            </div>
                            <div>
                                <p className="season-text-2" onClick={ ( ) => { activeSeason( 2 )  } }>Temporada 2</p>
                                <img src={woodSing} alt="Image Season 2" className="sing-season-2" />
                            </div>
                        </div>
                        {   
                            seasonClick === 1  ? (
                                <>
                                    <p className="text-galery_2">
                                        Temporada 1 - Festival periferia "Emisora la terraza". <br />
                                        La primer experiencia de nuestra emisora se lanzó bajo las ventajas del libro "esteticas
                                        amarradas con alambres" antecedentes que nos sirvio para vincular la 6ta version
                                        del festival de los artes periferia a travez de de una emisora inherante que abordo
                                        15 programas a manera de magazin audiovisual transmitido por plataformas digitales
                                        donde los artes, artistas y narrativas de los y las participantes se involuclaron 
                                        en a la memoria historica y las esteticas propias de la localidad 19 de ciudad
                                        Bolívar.
                                        Iniciativa ganadora de "Beca para la realiazcion de eventos artisticos y culturales en la
                                        localidad de Ciudad Bolivar". 2021.
                                    </p>
                                    { sizeBool ? (
                                        <>
                                            <Row className="container-all-galery">
                                                <Col>
                                                    <img src={Image_1} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_4} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_3} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_5} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_6} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_7} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_9} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_8} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_10} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_11} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_13} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_15} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_12} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_14} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_16} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_17} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_19} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_21} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_18} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_20} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_22} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_23} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_25} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_24} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_26} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <>
                                            <Row className="container-all-galery">
                                                <Col>
                                                    <img src={Image_1} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_4} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_3} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_5} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_6} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_7} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_9} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_8} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_10} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_11} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_13} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_15} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_12} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_14} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_16} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_17} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_19} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_21} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_18} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_20} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_22} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_23} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_25} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_24} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_26} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                            </Row>
                                        </>
                                    )} 
                                </>
                                
                            ) : (
                                <>
                                    <p className="text-galery_2">
                                        Temporada 2 Texto Temporada 2 Texto Temporada 2 TextoTemporada 2 TextoTemporada 2 TextoTemporada 2 TextoTemporada 2 TextoTemporada 2 Texto
                                        Temporada 2 TextoTemporada 2 TextoTemporada 2 TextoTemporada 2 TextoTemporada 2 TextoTemporada 2 TextoTemporada 2 Texto 
                                        Temporada 2 TextoTemporada 2 TextoTemporada 2 TextoTemporada 2 TextoTemporada 2 TextoTemporada 2 TextoTemporada 2 Texto  
                                    </p>
                                    { sizeBool ? (
                                        <>
                                            <Row className="container-all-galery">
                                                <Col>
                                                    <img src={Image_2_1} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_4} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_2} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_3} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_5} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_6} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_7} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_9} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_8} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_10} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_11} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_13} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_15} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_12} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_14} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_16} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_17} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_19} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_21} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_18} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_20} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_22} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_23} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_25} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_24} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_26} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                    <img src={Image_2_27} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" style={{width:"100%"}} />
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <>
                                            <Row className="container-all-galery">
                                                <Col>
                                                    <img src={Image_2_1} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_4} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_2} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_3} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_5} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_6} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_7} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_9} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_8} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_10} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_11} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_13} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_15} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_12} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_14} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_16} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_17} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_19} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_21} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_18} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_20} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_22} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_23} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_25} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_24} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                                <Col>
                                                    <img src={Image_2_26} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                    <img src={Image_2_27} alt="" onClick={( e ) => { showModal(e) }} className="m-2 img-border-galery" />
                                                </Col>
                                            </Row>
                                        </>
                                    )} 
                                </>
                            )
                        }
                        
                    </div>
                </Container>
                <Modal show={show} onHide={handleClose} className="modal-padding-image" centered>
                    <Modal.Body>
                        { imageChange != '' ? (
                            <>
                               <img src={imageChange} alt="Image Complete" className="image-modal" /> 
                            </>
                        ) : 
                        ( <></> )
                        }
                    </Modal.Body>               
                </Modal>
            </section>
        </>
    );
}

export default Galery; 