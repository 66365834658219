import React from "react";
import './css/Footer.css';
import logosFooter from "../../assets/img/Components/Footer/logos.png"; 

const Footer = () => {
    return(
        <div className="footer-bg">
            <img src={logosFooter} alt="Logos Footer" className="footer-imgs" />
        </div>
    );
}

export default Footer;