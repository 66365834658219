import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import './css/Header.css';

const Header = ({imgName, altImg, site}) => {
    let navigate = useNavigate();
    return (
        <>
            <Navbar className="imgBackground">
                <Container className="justify-content-center">
                    <Navbar.Brand
                        onClick={() => { navigate(site)}}
                    >
                        <img 
                            src={imgName}
                            className="logo-header"
                            alt={altImg}
                        />
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;