import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import "./css/Emisions.css";

// Interview Images
import interview_1 from "../../assets/img/Views/Galery/Images_season2/interview_1.jpg";
import interview_2 from "../../assets/img/Views/Galery/Images_season2/interview_2.jpg";
import interview_3 from "../../assets/img/Views/Galery/Images_season2/interview_3.jpg";
import interview_4 from "../../assets/img/Views/Galery/Images_season2/interview_4.jpg";
import interview_5 from "../../assets/img/Views/Galery/Images_season2/interview_5.jpg";
import interview_6 from "../../assets/img/Views/Galery/Images_season2/interview_6.jpg";
import interview_7 from "../../assets/img/Views/Galery/Images_season2/interview_7.jpg";
import interview_8 from "../../assets/img/Views/Galery/Images_season2/interview_8.jpg";


// Interview Audio

import audio_1 from "../../assets/audio/EMISORA_LA_TERRAZA_Blanca_Pineda.mp3";
import audio_2 from "../../assets/audio/EMISORA_LA_TERRAZA_Felipe_Avila.mp3";
import audio_3 from "../../assets/audio/EMISORA_LA_TERRAZA_Siendo_Franco.mp3";
import audio_4 from "../../assets/audio/EMISORA_LA_TERRAZA_Semillas_Creativas.mp3";
import audio_5 from "../../assets/audio/EMISORA_LA_TERRAZA_Masai_Ban-Go.mp3";
import audio_6 from "../../assets/audio/EMISORA_LA_TERRAZA_Fenomenos_Crew.mp3";
import audio_7 from "../../assets/audio/EMISORA_LA_TERRAZA_Maria_Mora.mp3";
import audio_8 from "../../assets/audio/EMISORA_LA_TERRAZA_Crew.mp3";

// Images

import stopIco from "../../assets/img/Views/Emisions/stopImg.png";
import playIco from "../../assets/img/Views/Emisions/playImg.png";
import pauseIco from "../../assets/img/Views/Emisions/pauseImg.png";

const Emisions = () => {

    const [styleBool, setStyleBool] = useState(false);

    const handlePlay = ( action, IdElementDom ) => {
        let audio = document.getElementById( IdElementDom );
        audio.play();

    }

    const handlePause = ( action, IdElementDom ) => {
        let audio = document.getElementById( IdElementDom );
        audio.pause();
    }

    const handleStop = ( action, IdElementDom ) => {
        let audio = document.getElementById( IdElementDom );
        audio.ended = true;
    }


    let dataEmisions = [
        {
            name: "Entrevista: Blanca Pineda",
            urlImage: interview_1,
            urlAudio: audio_1,
            classN: 'interview-name',
            classI: 'interview-mage',
            classA: 'interview-audio',
            idElement: 'audioBalnca'
        },
        {
            name: "Entrevista: Felipe Avila",
            urlImage: interview_2,
            urlAudio: audio_2,
            classN: 'interview-name',
            classI: 'interview-mage',
            classA: 'interview-audio',
            idElement: 'audioFelipe'
        },
        {
            name: "Entrevista: Siendo Franco",
            urlImage: interview_3,
            urlAudio: audio_3,
            classN: 'interview-name',
            classI: 'interview-mage',
            classA: 'interview-audio',
            idElement: 'audioSiendo'
        },
        {
            name: "Entrevista: Semillas Creativas",
            urlImage: interview_4,
            urlAudio: audio_4,
            classN: 'interview-name',
            classI: 'interview-mage',
            classA: 'interview-audio',
            idElement: 'audioSemillas'
        },
        {
            name: "Entrevista: Masai Ban-Go",
            urlImage: interview_5,
            urlAudio: audio_5,
            classN: 'interview-name',
            classI: 'interview-mage',
            classA: 'interview-audio',
            idElement: 'audioMassai'
        },
        {
            name: "Entrevista: Fenomenos Crew",
            urlImage: interview_6,
            urlAudio: audio_6,
            classN: 'interview-name',
            classI: 'interview-mage',
            classA: 'interview-audio',
            idElement: 'audioFenomenos'
        },
        {
            name: "Entrevista: Maria Mora",
            urlImage: interview_7,
            urlAudio: audio_7,
            classN: 'interview-name',
            classI: 'interview-mage',
            classA: 'interview-audio',
            idElement: 'audioMaria'
        },        
        {
            name: "Entrevista: Crew",
            urlImage: interview_8,
            urlAudio: audio_8,
            classN: 'interview-name',
            classI: 'interview-mage',
            classA: 'interview-audio',
            idElement: 'audioCrew'
        }
    ]

    return (
        <>
            <section className="bg-emissions-container">
                <Container>
                    <div className="emisions-div-principal">
                        <p>EMISIONES</p>
                    </div>
                    {
                        dataEmisions.map( ( e, i ) => {
                            console.log( e );
                            return ( 
                                <>
                                    <div className="card">
                                        <div className="card-body">
                                            <p  className={e.classN}>{e.name}</p>
                                            <img className={e.classI} src={e.urlImage} alt="Entrevista Imagen" /> <br />
                                            { !styleBool ? (
                                                <>
                                                    <div>
                                                        <audio id={e.idElement} className={e.classA} src={e.urlAudio} type="audio/mpeg" controls />
                                                        {/* <Button variant="primary" onClick={ ( e ) => { audioPlay( e ) } } >Play</Button> */}
                                                        {/* <div className="reproductor-container">
                                                            <span className="icons-player">
                                                                <img src={playIco} alt="Play" className="play-icon" onClick={ ( x ) => {handlePlay( x, e.idElement )}} />
                                                                <img src={pauseIco} alt="Pause" className="pause-icon" onClick={ ( x ) => {handlePause( x, e.idElement )}} />
                                                                <img src={stopIco} alt="Stop" className="stop-icon" onClick={ ( x ) => {handleStop( x, e.idElement )}} />
                                                            </span>
                                                        </div> */}
                                                    </div>
                                                </>
                                            
                                            ) : (
                                                <>
                                                    <div>
                                                        {/* <img src={} alt="play" />
                                                        <img src={} alt="pause" />
                                                        <img src={} alt="stop" /> */}
                                                    </div>
                                                </>
                                            ) }
                                        </div>
                                    </div> 
                                </>
                            )
                        })
                    }
                </Container>
            </section>
        </>
    );
}

export default Emisions;