import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../src/views/Home/Home.jsx';
import About from '../src/views/About/About.jsx';
import Header from './components/Header/Header';
import Emisions from './views/Emisions/Emisions';
import Galery from './views/Galery/Galery';

//Header Images
import LogoTerraza from '../src/assets/img/Components/Header/LogoHeader.png';
import Footer from './components/Footer/Footer';
import Videos from './views/Videos/Videos';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header 
          imgName = {LogoTerraza}
          altImg = "Logo La Terraza"
          site = "/inicio"
        />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/inicio' element={<Home />}></Route>
          <Route path='/somos' element={<About />}></Route>
          <Route path='/galeria' element={<Galery />}></Route>
          <Route path='/emisiones' element={<Emisions />}></Route>
          <Route path='/videos' element={<Videos />}></Route>
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
