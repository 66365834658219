import React from 'react';
import { Container } from 'react-bootstrap';
import "./css/Videos.css";

const Videos = () => {

    let listVideosUrls = [
        {
            srcVideo: 'https://www.youtube.com/embed/X6m-ueK3x2k?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC',
            titleTag: 'La Terraza Segunda Temporada',
            title: 'La Terraza Segunda Temporada',
            text: `iniciativa ganadora de la CONVOCATORIA ES CULTURA LOCAL PARA EL FORTALECIMIENTO DE INICIATIVAS ARTÍSTICAS Y CULTURALES DE LA LOCALIDAD DE CIUDAD BOLÍVAR 2021.`
        },
        {
            srcVideo: 'https://www.youtube.com/embed/kIPGT4GW7EE?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC',
            titleTag: 'T. 2 - T. 2 - Cap. 1',
            title: 'Capitulo 1: Blanca Pineda',
            text: 'Arrancamos esta segunda temporada con una de las mujeres mas berracas de nuestra localidad, con mas de 19 publicaciones, mas de 30 años de investigación, activismo, lucha y procesos comunitarios, ella es la señora Blanca Pineda "la historiadora de Ciudad Bolívar".'
        },
        {
            srcVideo: 'https://www.youtube.com/embed/zqkEXe7Uy9I?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC',
            titleTag: 'T. 2 - Cap. 2',
            title: 'Capitulo 2: Felipe Avila',
            text: 'En el día de hoy desde la terraza de la esquina del puente  estaremos con el artista Audiovisual FELIPE AVILA, quien nos dará un resumen de como es hacer un cine recursivo en Ciudad Bolívar, el camaleón del cine valiente expone sus mas de 10 proyectos entre los cuales destacan 3   largometrajes rodados en nuestra localidad.'
        },
        {
            srcVideo: 'https://www.youtube.com/embed/r2ChEce0kyk?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC',
            titleTag: 'T. 2 - Cap. 3',
            title: 'Capitulo 3: Siendo Franco',
            text: 'Hoy estaremos con la agrupación de rap  reggae de la localidad SIENDO FRANCO, artista que ha tenido un arduo trabajo musical y artístico en la localidad, siendo un referente en Rap a nivel Distrital.'
        },
        {
            srcVideo: 'https://www.youtube.com/embed/-bJ0liQbVs8?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC',
            titleTag: 'T. 2 - Cap. 4',
            title: 'Capitulo 4: Semillas Creativas',
            text: 'Hoy estamos con Semillas Creativas un colectivo que ha trabajado desde los años 80 en el barrio Juan pablo II, haciendo procesos apartir del arte, la memoria y la educaciòn.'
        },
        {
            srcVideo: 'https://www.youtube.com/embed/X8Na-lJ1OQI?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC',
            titleTag: 'T. 2 - Cap. 5',
            title: 'Capitulo 5: Masai Ban-Go',
            text: 'hoy estaremos con una de las agrupaciones de Hip Hop mas legendarias de la localidad  de Ciudad Bolívar, artista que ha tenido un arduo trabajo musical y artístico en la localidad, siendo un referente en Rap a nivel Distrital.'
        },
        {
            srcVideo: 'https://www.youtube.com/embed/hNlT18nD030?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC',
            titleTag: 'T. 2 - Cap. 6',
            title: 'Capitulo 6: Fenomenos Crew',
            text: 'Magazin Radio la terraza beneficiarios de CONVOCATORIA ES CULTURA LOCAL PARA EL FORTALECIMIENTO DE INICIATIVAS ARTÍSTICAS Y CULTURALES DE LA LOCALIDAD DE CIUDAD BOLÍVAR 2021.'
        },
        {
            srcVideo: 'https://www.youtube.com/embed/v2JYLMHEibQ?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC',
            titleTag: 'T. 2 - Cap. 7',
            title: 'Capitulo 7: Maria Mora',
            text: 'Hoy estamos con una de las mayoras de la localidad 19 de ciudad Bolívar, madre, artesana, líder y inspiración para que hoy en día muchas mujeres sean productivas y productoras, este capitulo esta cargado de la historia de una lucha de muchas luchas.'
        },
        {
            srcVideo: 'https://www.youtube.com/embed/fZpbw-gNohU?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC',
            titleTag: 'T. 2 - Cap. 8',
            title: 'Capitulo 8: OVC Crew',
            text: 'Hoy estamos con una de las escuelas de Break Dance mas emblemáticas no solo de Ciudad Bolívar sino que también es un gran referente Distrital.'
        }
    ]

    return (
        <>
            <section className="container-videos mt-2">
                <div className="d-flex justify-content-center">
                    <p className="videos-title">VIDEOS</p>
                </div>
                <Container>
                    <div className='background-videos'>
                        {
                            listVideosUrls.map( ( e, key ) => {
                                return (
                                    <>
                                        <p key={key} className='title-videos-iframe mt-5'>{e.title}</p>
                                        <p key={key} className='text-videos-iframe'>{e.text}</p>
                                        <iframe key={key} 
                                            className='videos-iframe mt-3'
                                            src={e.srcVideo}
                                            title={`YouTube video ${e.titleTag}`} 
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                            allowFullScreen>
                                        </iframe>
                                    </>
                                )
                            })
                        }
                    </div>
                </Container>
            </section>
        </>
    );
}

export default Videos;

