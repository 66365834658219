import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CarouselImgs from '../../components/CarouselImgs/CarouselImgs';
import './css/Home.css';
//Images 
import GaleryImg from '../../assets/img/Views/Home/Galeria.png';
import VideoImg from '../../assets/img/Views/Home/Videos.png';
import imgWall from "../../assets/img/Views/Home/ladrillos.jpg";
//Carousel
import img_1 from "../../assets/img/Views/Home/ImagesCarousel/Logo_Festival_Periferia.png";
import img_2 from "../../assets/img/Views/Galery/GaleryImages/21.jpg";
import img_3 from "../../assets/img/Views/Galery/GaleryImages/2.jpg";


const Home = () => {

    let navigate = useNavigate();
    
    

    const sizeAllTime =  () => {
        return window.screen.width;
    }

    let globalSize;

    const [sizeBool, setSizeBool ] = useState(true);


    let size ;
    setInterval(() => {
        size = sizeAllTime();
        globalSize = size;
        if ( globalSize >= 770 ) {
            setSizeBool(true);
        } else {
            setSizeBool(false);
        }
    }, 100);


    let imgsObject = [
        {
            urlImg: img_1,
            imgAlt: 'Logo Periferia'
        },
        {
            urlImg: img_2,
            imgAlt: 'Foto Temporada 1'
        },
        {
            urlImg: img_3,
            imgAlt: 'Foto temporada 1'
        }
    ];

    let emisionsSeasonOne = [
        {date: '01/11/2021'},
        {date: '02/11/2021'},
        {date: '03/11/2021'},
        {date: '05/11/2021'},
        {date: '06/11/2021'},
        {date: '07/11/2021'}
    ];
    
    let emisionsSeasonTwo = [
        {date : '15/02/2022'},
        {date : '25/02/2022'},
        {date : '26/02/2022'},
        {date : '04/02/2022'},
        {date : '05/02/2022'} 
    ];
    
   

    


    return(
        <>
            <div className='bakgorund-img-body-home'>
                <CarouselImgs imgs={imgsObject}/>
                <Container className='container-options'>
                    {
                        !sizeBool ? (
                            <>
                                <Row className="mobile-size">
                                    <button className='btn btn-danger style-btns-shadow botton-text position-div-relative mobile-title' onClick={ () => {navigate("/emisiones")}} >EMISIONES</button>
                                    <Row className="m-0 row-dates">
                                        <Col>
                                            <h6 className='season-one'>1er Temporada</h6>
                                            {
                                                emisionsSeasonOne.map((e, i) => {
                                                    return (
                                                        <p key={i} className='date-text-font'>{e.date}</p>
                                                    )
                                                })
                                            }
                                        </Col>
                                        <Col>
                                            <h6 className='season-two'>2da Temporada</h6>
                                            {
                                                emisionsSeasonTwo.map((e, i) => {
                                                    return (
                                                        <p key={i} className='date-text-font'>{e.date}</p>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Row>
                                    <div className='col-galery'>
                                        <button className='btn btn-primary style-btns-shadow botton-text position-div-relative' onClick={ () => {navigate("/galeria")}} >GALERIA</button>
                                        <div>
                                            <img src={GaleryImg} alt="Galeria Imagenes" className='imgGalery'/>
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div>
                                        <button className='btn btn-warning style-btns-shadow botton-text position-div-relative'onClick={ () => {navigate("/videos")}}>VIDEOS</button>
                                        <div>
                                            <img src={VideoImg} alt="Video Imagen" className='imgVideo' />
                                        </div>
                                    </div>
                                    <div>
                                        <button className='btn btn-success style-btns-shadow botton-text' onClick={ () => {navigate("/somos")}}>SOMOS</button>
                                        <p className='text-about-parr'>
                                            Periferia Producciones es
                                            una productora audiovisual, organización de promoción social y artística 
                                            que nace bajo las instalaciones de la casa mayaelo en Ciudad Bolívar como un ejercicio de impulso de 
                                            lo que denominamos “Estéticas amarradas con alambres “. cómo construir comunidades a través del arte y 
                                            la pedagogía, en este ejercicio hemos realizado acciones artísticas, encuentros pedagógicos, que permiten un 
                                            intercambio de saberes en el marco de las otras estéticas.
                                        </p>
                                    </div>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row>
                                    <Col>
                                        <button className='btn btn-danger style-btns-shadow botton-text position-div-relative' onClick={ () => {navigate("/emisiones")}} >EMISIONES</button>
                                        <Row>
                                            <Col>
                                                <h6 className='season-one'>1er Temporada</h6>
                                                {
                                                    emisionsSeasonOne.map((e, i) => {
                                                        return (
                                                            <p key={i} className='date-text-font'>{e.date}</p>
                                                        )
                                                    })
                                                }
                                            </Col>
                                            <Col>
                                                <h6 className='season-two'>2da Temporada</h6>
                                                {
                                                    emisionsSeasonTwo.map((e, i) => {
                                                        return (
                                                            <p key={i} className='date-text-font'>{e.date}</p>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-galery">
                                        <button className='btn btn-primary style-btns-shadow botton-text position-div-relative' onClick={ () => {navigate("/galeria")}} >GALERIA</button>
                                        <div>
                                            <img src={GaleryImg} alt="Galeria Imagenes" className='imgGalery'/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <button className='btn btn-warning style-btns-shadow botton-text'onClick={ () => {navigate("/videos")}}>VIDEOS</button>
                                        <div>
                                            <img src={VideoImg} alt="Video Imagen" className='imgVideo' />
                                        </div>
                                    </Col>
                                    <Col>
                                        <button className='btn btn-success style-btns-shadow botton-text' onClick={ () => {navigate("/somos")}}>SOMOS</button>
                                        <p className='text-about-parr'>
                                            Periferia Producciones es
                                            una productora audiovisual, organización de promoción social y artística 
                                            que nace bajo las instalaciones de la casa mayaelo en Ciudad Bolívar como un ejercicio de impulso de 
                                            lo que denominamos “Estéticas amarradas con alambres “. cómo construir comunidades a través del arte y 
                                            la pedagogía, en este ejercicio hemos realizado acciones artísticas, encuentros pedagógicos, que permiten un 
                                            intercambio de saberes en el marco de las otras estéticas.
                                        </p>
                                    </Col>
                                </Row>
                            </>
                        ) 
                    }
                  
                </Container>
            </div>
            <img src={imgWall} alt="Ladrillos" className='footer-image-above' />
        </>
    );
}

export default Home;