import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./css/About.css";
import logoPeriferia from "../../assets/img/Views/About/logo_periferia.jpg";
import imgBodyAbout from "../../assets/img/Views/About/Foto_img.png";
import ramaImageI from "../../assets/img/Views/About/ramaLateral-l.png";
import ramaImageD from "../../assets/img/Views/About/ramaLateral-d.png";
import imgWall from "../../assets/img/Views/Home/ladrillos.jpg";
import logo2 from "../../assets/img/Views/About/LogoPeriferia_2.png";
import recorder from "../../assets/img/Views/About/recorder_about.png";

const About = () => {


    return (
        <>
            <div className="bakgorund-img-body">
            <Container className="container-about-all">
                    <Row>
                        <Col lg="1" className="p-0">
                            <img src={ramaImageI} alt="Rama Lateral" className="rama-lateral-l" />
                        </Col>
                        <Col lg="10" className="col-container-about">
                        <div className="d-flex justify-content-center">
                            <p className="about-title">SOMOS</p>
                        </div>
                        <Container className="container-all-inside mb-4">
                            <div className="container-imgs-inicio">
                                <img src={logo2} alt="Logo Periferia 2" className="periferia-2" />
                                <img 
                                    src={logoPeriferia} 
                                    alt="Logo Periferia" 
                                    className="logo-perifery"    
                                />
                                <img src={recorder} alt="Grabadora" className="recorder-periferia" />
                            </div>
                            <p className="text-about">
                                Periferia Producciones es una productora audiovisual, organización de promoción 
                                social y artística que nace bajo las instalaciones de la casa mayaelo en Ciudad Bolívar 
                                como un ejercicio de impulso de lo que denominamos “Estéticas amarradas con alambres “. 
                                cómo construir comunidades a través del arte y la pedagogía, en este ejercicio hemos 
                                realizado acciones artísticas, encuentros pedagógicos, que permiten un intercambio de saberes 
                                en el marco de las otras estéticas. Tomando como fundamento La cultura y el territorio como un 
                                escenario de construcción sensible de y hacia nuestras realidades. <br />
                                Contamos con un historial de ejecución exitosa de proyectos como: <br /> Por el tunjuelo: proyecto social y 
                                documental audiovisual iniciativa ganadora del programa distrital de estímulos 2018, <br />  
                                el PDB (plan de desarrollo barrial) beneficiado a través del proyecto Uno +Uno=Todos. Una + Una igual Todas- Fondo 
                                de Desarrollo Local de Ciudad Bolívar 2021,  práctica de desarrollo sostenible desde las comunidades, 
                                programas de formación artística, investigación y aportes a la recuperación del ecosistema subxerofítico presente 
                                en gran parte de la localidad. El más importante ejercicio de enunciación lo realizamos consecutivamente y 
                                de manera anual articulado con diferentes colectividades y es el Festival Periferia; 
                                semana en la que se propone una gran puesta en escena desde y para la comunidad en torno a las artes 
                                y elementos propios de contextos de diferentes comunidades. El festival en su esencia comparte con artistas, 
                                gestores y actores culturales un espacio de diálogos en torno a lo técnico, lo poético y el compromiso del 
                                arte como un transformador tanto de contextos como de vidas.
                            </p>
                        </Container>
                        <img src={imgBodyAbout} alt="Foto" className="img-body"/>
                        <h5 className="mision-title">Misión:</h5>
                        <p className="mision-text">
                            sistematizar la memoria histórica,la riqueza ambiental y cultural,   los trabajos y productos artísticos en 
                            contextos periurbanos  a través de diferentes proyectos de intervención social y artística, promover referentes, 
                            experiencias y  prácticas artísticas como herramientas,fundamentos y fuentes que posibiliten la transformación social.
                        </p>
                        <h5 className="vision-title">Visión:</h5>
                        <p className="vision-text">
                            Ser una reconocida y  confiable fuente de divulgación  de contenidos en  patrimonio, memoria histórica,  arte, cultura, 
                            tecnología, entretenimiento, diseño e innovación.
                        </p>
                        <h5 className="target-title">Objetivos:</h5>
                        <ul className="target-text">
                            <li>
                                sistematizar y construir una memoria  colectiva desde las narrativas y  manifestaciones sociales, artísticas y 
                                culturales  a través de actores o agentes territoriales  por medio de  la producción transmedia principalmente a 
                                manera  de magazine.
                            </li>
                        </ul>
                        </Col>
                        <Col lg="1" className="p-0">
                            <img src={ramaImageD} alt="Rama Laterla Derecha" className="rama-lateral-d" />  
                        </Col>
                    </Row>
                </Container>
            </div>
            <img src={imgWall} alt="Ladrillos" className='footer-image-above' />
        </>
    );
}

export default About;